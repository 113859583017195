
import Vue from "vue";
import { PropType } from "vue";

interface ItemDisplay {
  bloqueado: boolean;
  validado: string; // data
  numPatrimonio: string;
}

export default Vue.extend({
  name: "ValidacaoMercurioIcon",
  props: {
    item: {
      type: Object as PropType<ItemDisplay>,
      default: null,
    },
  },
});
