
import Vue from "vue";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import DeclaracaoItemFormDialog from "../../components/declaracao/DeclaracaoItemFormDialog.vue";
import PrefeituraImportacaoCard from "./PrefeituraImportacaoCard.vue";
import TabelaErrosImportacao from "../TabelaErrosImportacao.vue";
import requester from "../../requester";
import { Categoria } from "@back-src/models/categoria.model";
import CustomText from "../../components/CustomText.vue";
export default Vue.extend({
  components: {
    PrefeituraImportacaoCard,
    DeclaracaoItemFormDialog,
    TabelaErrosImportacao,
    CustomText,
  },
  name: "PrefeituraAnimaisTabItem",
  props: {
    unidadeId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      animaisDeclarados: true,
      categorias: true,
      importacao: false,
      declaracao: false,
    },
    msgImportacao: "Deseja importar os animais declarados em anos anteriores?",
    search: null,
    importacaoRejeitada: false,
    declaracaoItemId: null,
    declaracao: null,
    alterarDeclaracaoItem: false,
    dialogDeclaracaoItem: false,
    animaisDeclarados: [] as DeclaracaoItem[] | null,
    categorias: null as Categoria[] | null,
    header: [
      {
        value: "id",
        text: "Id",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "especie",
        text: "Animal",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "categoria",
        text: "Categoria",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },

      {
        value: "diasUso",
        text: "Dias de utilização",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "lote",
        text: "Lote",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "center",
      },
      {
        value: "qtdLote",
        text: "Qtde de animais",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "obs",
        text: "Observações",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "actions",
        text: "Alterar",
        sortable: false,
        filterable: false,
        class: "text-no-wrap",
      },
    ],
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    declaracaoItemsComputed() {
      return [];
    },
    oferecerImportacaoAnimais() {
      return this.animaisDeclarados.length === 0;
    },
    animaisDeclaradosComputed() {
      return (
        this.animaisDeclarados.map((animal) => {
          const categoria = this.categorias.find(
            (categoria) => categoria.id === animal.categoriaId
          );
          return {
            ...animal,
            especie: categoria?.especie?.nome,
            categoria: categoria?.nome,
          };
        }) || []
      );
    },
  },
  async mounted() {
    await Promise.all([
      this.buscaDeclaracao(),
      this.listaCategorias(),
      this.listaAnimaisDeclarados(),
    ]);
  },
  methods: {
    async buscarAnimaisEDeclaracao() {
      await Promise.all([
        this.buscaDeclaracao(),
        this.listaAnimaisDeclarados(),
      ]);
    },
    async recusarImportacao() {
      const confirm = await this.$root.$confirm(
        "Não importar animais",
        "Se prosseguir não será mais possível importar os dados dos animais declarados em anos anteriores e você deverá realizar o cadastro manualmente. Tem certeza que deseja prosseguir?",
        {
          textConfirm: "Sim, prosseguir",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.importacaoRejeitada = true;
    },
    openDialogInfo(declaracaoItemId: number) {
      this.declaracaoItemId = declaracaoItemId;
      this.alterarDeclaracaoItem = true;
      this.dialogDeclaracaoItem = true;
    },
    async listaAnimaisDeclarados() {
      this.loading.animaisDeclarados = true;
      try {
        this.animaisDeclarados =
          await requester.unidade.private.listaDeclaracaoItemsPrefeitura(
            this.unidadeId
          );
        this.loading.animaisDeclarados = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
        this.loading.categorias = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async buscaDeclaracao() {
      this.loading.declaracao = true;
      try {
        this.declaracao = await requester.unidade.private.buscaDeclaracaoAtual(
          this.unidadeId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracao = false;
      }
    },
  },
});
