
import requester from "@/requester";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import DeclaracaoVerConflitoDialog from "@/components/declaracao/DeclaracaoVerConflitoDialog.vue";
import Vue from "vue";
import { Declaracao } from "@back-src/models/declaracao.model";
import { ItemDeclaradoVerificacao } from "@/utils/constants";

export default Vue.extend({
  name: "TabelaErrosImportacao",
  components: {
    DeclaracaoVerConflitoDialog,
  },
  props: {
    errosImportacao: {
      type: Array,
      default: () => [],
    },
    periodoDeclaracaoId: {
      type: Number,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Num. Patrimônio",
        value: "item.numPatrimonio",
        width: "15%",
      },
      {
        text: "Num. cadastro",
        value: "item.numCadastro",
        width: "15%",
      },
      {
        text: "Erro",
        value: "erro",
      },
      {
        text: "Ver conflitos",
        value: "actions",
        sortable: false,
        align: "center",
        width: "15%",
      },
    ],
    loading: false,
    itemSelecionado: null,
    animaisDeclarados: [] as DeclaracaoItem[], // animais que foram declarados com mesmo numero patr/cadast que o selecionado (resultado de busca)
    dialogConflitos: false,
  }),
  computed: {
    animaisDeclaradosIds() {
      return this.animaisDeclarados.map((el) => el.id);
    },
    itens() {
      return this.errosImportacao;
    },
    diasDisponiveis() {
      if (!this.itemSelecionado || this.animaisDeclarados.length === 0) {
        return 0;
      }
      const somaDiasUso = this.animaisDeclarados.reduce(
        (diasTotais: number, el: DeclaracaoItem): number => {
          diasTotais += el.diasUso;
          return diasTotais;
        },
        0
      );
      return 365 - somaDiasUso >= 0 ? 365 - somaDiasUso : 0;
    },
  },
  methods: {
    pushToAnimaisDeclarados(declaracoes: Declaracao[]) {
      declaracoes.forEach((declaracao: Declaracao) =>
        declaracao.declaracaoItems.forEach((item: DeclaracaoItem) => {
          // verifica se item já está no array
          if (!this.animaisDeclaradosIds.includes(item.id)) {
            this.animaisDeclarados.push({
              id: item.id,
              especie: item.categoria?.especie?.nome || null,
              categoria: item.categoria
                ? JSON.parse(JSON.stringify(item.categoria))
                : null,
              completa: declaracao.completa,
              diasUso: item.diasUso || 0,
              qtdLote: item.qtdLote || 0,
              numCadastro: item.numCadastro,
              numPatrimonio: item.numPatrimonio,
              tipoDeclarante: declaracao.tipoDeclarante,
              unidadeSigla: declaracao.unidade?.sigla || null,
              usuarioEmailPrincipal:
                declaracao?.usuarioUpdatedBy?.emailPrincipal || null,
              usuarioNome: declaracao?.usuarioUpdatedBy?.nome || null,
              usuarioTelefone: declaracao?.usuarioUpdatedBy?.telefone || null,
              updatedAt: item.updatedAt || null,
            } as ItemDeclaradoVerificacao);
          }
        })
      );
    },
    async showDialogConflitos(item: DeclaracaoItem) {
      // verifica ambos os numeros. Animais repetidos NAO SERAO incluidos no array...
      //... por conta da funcao pushToAnimaisDeclarados()
      await this.verificaNumPatrimonio(item);
      await this.verificaNumCadastro(item);
      this.itemSelecionado = item;
      this.dialogConflitos = true;
    },
    async verificaNumPatrimonio(item: DeclaracaoItem) {
      this.loading = true;
      try {
        const declaracoes =
          await requester.periodoDeclaracao.private.verificaNumPatrimonio(
            this.periodoDeclaracaoId,
            item.numPatrimonio,
            null
          );
        this.pushToAnimaisDeclarados(declaracoes);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async verificaNumCadastro(item: DeclaracaoItem) {
      this.loading = true;
      try {
        const declaracoes =
          await requester.periodoDeclaracao.private.verificaNumCadastro(
            this.periodoDeclaracaoId,
            item.numCadastro,
            null
          );
        this.pushToAnimaisDeclarados(declaracoes);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    dialogConflitos() {
      if (!this.dialogConflitos) {
        this.itemSelecionado = null;
        this.animaisDeclarados = [] as DeclaracaoItem[];
      }
    },
  },
});
