
import Vue from "vue";
import { Categoria } from "@back-src/models/categoria.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import CustomText from "../CustomText.vue";
import requester from "../../requester";
import CustomTabItem from "../CustomTabItem.vue";
import { Declaracao } from "@back-src/models/declaracao.model";
import AlterarItemReceitaDialog from "../unidade/AlterarItemReceitaDialog.vue";
import { AlteraItemReceitaDto } from "@back-src/declaracao/dto/altera-item-receita.dto";

export default Vue.extend({
  name: "DocenteReceitaTabItem",
  components: {
    AlterarItemReceitaDialog,
    CustomText,
    CustomTabItem,
  },
  props: {
    ver: {
      type: Boolean,
      default: false,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    usuarioId: {
      type: Number,
      default: null,
    },
    declaracaoAtualId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: {
      criaReceitas: false,
      categorias: true,
    },
    geraReceita: null,
    declaracao: null as Declaracao,
    dialogAlterarReceita: false,
    animaisDeclarados: [] as DeclaracaoItem[] | null,
    categorias: [] as Categoria[] | null,
    rendimentosAnimais: [],
    headers: [
      {
        value: "especie",
        text: "Espécie/Categoria",
        class: "text-no-wrap",
        sortable: true,
      },
      {
        value: "geraReceita",
        text: "Gera receita",
        class: "text-no-wrap",
        sortable: false,
        align: "right",
      },
      {
        value: "receitaProle",
        text: "Prole (R$)",
        class: "text-no-wrap",
        sortable: false,
        align: "right",
      },
      {
        value: "receitaSubprodutos",
        text: "Subprodutos (R$)",
        class: "text-no-wrap",
        sortable: false,
        align: "right",
      },
      {
        value: "receitaTransferencia",
        text: "Venda (R$)",
        class: "text-no-wrap",
        sortable: false,
        align: "right",
      },
      {
        value: "obs",
        text: "Observações",
        class: "text-no-wrap",
        sortable: false,
      },
    ],
  }),
  computed: {
    headerComputed() {
      return this.ver
        ? this.headers
        : [
            ...this.headers,
            {
              value: "actions",
              text: "Alterar",
              class: "text-no-wrap",
              sortable: false,
            },
          ];
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
  },
  async mounted() {
    if (!this.ver) {
      // primeiro atualiza as receitas
      await Promise.all([this.listaCategorias(), this.criaReceitas()]);
      // depois busca a declaracao
      await this.buscaDeclaracao();
    } else {
      await this.listaCategorias();
      await this.buscaDeclaracaoAdminById();
    }
    this.preencheArrayRendimentosAnimais();
  },
  methods: {
    async submitFormNaoGeraReceita(item) {
      this.geraReceita = null;
      const itemReceitaId = item.id;
      const formData = {
        geraReceita: false,
      } as AlteraItemReceitaDto;
      await requester.docente.private.alteraItemReceitaDocente(
        formData,
        itemReceitaId,
        this.declaracaoAtualId,
        this.usuarioId
      );
      item.geraReceita = false;
      this.$root.$emit("toast-success", "Valores alterados");
    },
    async criaReceitas() {
      this.loading.criaReceitas = true;
      if (!this.declaracaoAtualId) {
        return;
      }
      try {
        await requester.docente.private.criaEmLoteDeclaracaoReceitaDocente(
          this.declaracaoAtualId,
          this.usuarioId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.criaReceitas = false;
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    async buscaDeclaracao() {
      this.loading.declaracao = true;
      if (!this.declaracaoAtualId) {
        this.declaracao = null;
        return;
      }
      try {
        this.declaracao = await requester.docente.private.buscaDeclaracao(
          this.declaracaoAtualId,
          this.usuarioId
        );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracao = false;
      }
    },
    async buscaDeclaracaoAdminById() {
      this.loading.declaracao = true;
      if (!this.declaracaoAtualId) {
        this.declaracao = null;
        return;
      }
      try {
        this.declaracao =
          await requester.admin.private.buscaDeclaracaoByIdDocente(
            this.declaracaoAtualId,
            this.usuarioId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.declaracao = false;
      }
    },
    preencheArrayRendimentosAnimais() {
      if (!this.declaracao) return;
      this.rendimentosAnimais = [];
      this.declaracao.declaracaoReceitas.map((item) => {
        const categoria = this.categorias.find(
          (el) => el.id === item.categoriaId
        );
        this.rendimentosAnimais.push({
          ...item,
          especie: categoria?.especie?.nome,
          categoria: categoria?.nome,
          salvo: true,
        });
      });
      this.rendimentosAnimais.sort(function (a, b) {
        return a.especie > b.especie;
      });
    },
    atualizaItem(event) {
      const animalAlterado = this.rendimentosAnimais.find(
        (el) => el.id === event.id
      );
      animalAlterado.obs = event?.obs;
      animalAlterado.geraReceita = event?.geraReceita;
      animalAlterado.receitaProle = event?.receitaProle;
      animalAlterado.receitaTransferencia = event?.receitaTransferencia;
      animalAlterado.receitaSubprodutos = event?.receitaSubprodutos;
    },
    openDialogAlterarReceita(item, geraReceita?: boolean) {
      this.geraReceita = geraReceita || null;
      const itemReceitaCopia = JSON.parse(JSON.stringify(item));
      if (this.geraReceita) {
        itemReceitaCopia.geraReceita = true;
      }
      this.$refs.alterarItemReceitaDialog.preencheItemReceita(itemReceitaCopia);
      this.dialogAlterarReceita = true;
    },
  },
  watch: {
    async usuarioId() {
      if (!this.ver) {
        // primeiro atualiza as receitas
        await this.criaReceitas();
        // depois busca a declaracao
        await this.buscaDeclaracao();
      } else {
        await this.buscaDeclaracaoAdminById();
      }
      this.preencheArrayRendimentosAnimais();
    },
  },
});
