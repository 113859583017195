import axios from "axios";
import { AlteraDeclaracaoDto } from "@back-src/declaracao/dto/altera-declaracao.dto";
import { AlteraItemReceitaDto } from "@back-src/declaracao/dto/altera-item-receita.dto";
import { Declaracao } from "@back-src/models/declaracao.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { DeclaracaoReceita } from "@back-src/models/declaracao-receita.model";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import { DadosExportados } from "@back-src/utils/constants";
import { ResponseImportacao } from "@back-src/docente/interface/resultadoValidacao.interface";

//DECLARACAO
const alteraFlagConclusao = async (
  declaracaoId: number,
  usuarioId: number,
  form: AlteraDeclaracaoDto
): Promise<void | string> => {
  const res = await axios.put(
    `docentes/${usuarioId}/declaracoes/${declaracaoId}/altera_flag`,
    form
  );
  return res.data;
};

const buscaDeclaracaoByPeriodoId = async (
  usuarioId: number,
  periodoDeclaracaoId: number
): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracoes`
  );
  return res.data;
};

const buscaDeclaracao = async (
  declaracaoId: number,
  usuarioId: number
): Promise<Declaracao> => {
  const res = await axios.get<Declaracao>(
    `docentes/${usuarioId}/declaracao/${declaracaoId}`
  );
  return res.data;
};

const criaDeclaracao = async (usuarioId: number): Promise<void> => {
  const res = await axios.post(`docentes/${usuarioId}/declaracoes`);
  return res.data;
};

const criaDeclaracaoItemProjeto = async (
  form: FormData,
  declaracaoId: number,
  projetoId: number,
  docenteId: number
): Promise<void> => {
  const res = await axios.post(
    `docentes/${docenteId}/declaracao/${declaracaoId}/projeto_pesquisa/${projetoId}/itens_declaracao`,
    form
  );
  return res.data;
};

const importarItensDeProjeto = async (
  docenteId: number,
  declaracaoId: number,
  form: any
): Promise<ResponseImportacao> => {
  const res = await axios.post(
    `docentes/${docenteId}/declaracao/${declaracaoId}/importar_itens_declaracao`,
    form
  );
  return res.data;
};

const alteraDeclaracaoItemProjeto = async (
  declaracaoId: number,
  docenteId: number,
  form: FormData,
  itemDeclaracaoId: number,
  projetoId: number
): Promise<void> => {
  const res = await axios.put(
    `docentes/${docenteId}/declaracao/${declaracaoId}/projeto_pesquisa/${projetoId}/itens_declaracao/${itemDeclaracaoId}`,
    form
  );
  return res.data;
};
const removeDeclaracaoItem = async (
  declaracaoItemId: number,
  projetoId: number,
  docenteId: number
): Promise<void> => {
  const res = await axios.delete(
    `docentes/${docenteId}/projeto_pesquisa/${projetoId}/itens_declaracao/${declaracaoItemId}`
  );
  return res.data;
};

const listaDeclaracaoItemsProjeto = async (
  projetoId: number,
  usuarioId: number
): Promise<DeclaracaoItem[]> => {
  const res = await axios.get<DeclaracaoItem[]>(
    `docentes/${usuarioId}/projeto_pesquisa/${projetoId}/itens_declaracao`
  );
  return res.data;
};

//PROJETO PESQUISA
const criaProjetoPesquisa = async (
  formData: FormData,
  declaracaoId: number,
  periodoDeclaracaoId: number,
  usuarioId: number
): Promise<void> => {
  const res = await axios.post(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa`,
    formData
  );
  return res.data;
};
const alteraProjeto = async (
  form: FormData,
  declaracaoId: number,
  periodoDeclaracaoId: number,
  projetoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa> => {
  const res = await axios.put<ProjetoPesquisa>(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa/${projetoId}`,
    form
  );
  return res.data;
};
const listaProjetosByDeclaracaoId = async (
  declaracaoId: number,
  periodoDeclaracaoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa[]> => {
  const res = await axios.get<ProjetoPesquisa[]>(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa`
  );
  return res.data;
};

const listaProjetosByPeriodoId = async (
  periodoDeclaracaoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa[]> => {
  const res = await axios.get<ProjetoPesquisa[]>(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/projeto_pesquisa`
  );
  return res.data;
};

const buscaProjeto = async (
  declaracaoId: number,
  periodoDeclaracaoId: number,
  projetoId: number,
  usuarioId: number
): Promise<ProjetoPesquisa> => {
  const res = await axios.get<ProjetoPesquisa>(
    `docentes/${usuarioId}/periodo/${periodoDeclaracaoId}/declaracao/${declaracaoId}/projeto_pesquisa/${projetoId}`
  );
  return res.data;
};

// RECEITAS

const alteraItemReceitaDocente = async (
  form: AlteraItemReceitaDto,
  itemDeclaracaoId: number,
  declaracaoId: number,
  usuarioId: number
): Promise<void> => {
  const res = await axios.put(
    `docentes/${usuarioId}/declaracao/${declaracaoId}/itens_receita/${itemDeclaracaoId}`,
    form
  );
  return res.data;
};
const criaEmLoteDeclaracaoReceitaDocente = async (
  declaracaoId: number,
  usuarioId: number
): Promise<void> => {
  const res = await axios.post(
    `docentes/${usuarioId}/declaracao/${declaracaoId}/itens_receita`
  );
  return res.data;
};
const listaDeclaracaoReceitaDocente = async (
  declaracaoId,
  usuarioId
): Promise<DeclaracaoReceita[]> => {
  const res = await axios.get<DeclaracaoReceita[]>(
    `docentes/${usuarioId}/declaracao/${declaracaoId}/itens_receita`
  );
  return res.data;
};

// EXPORTAR DADOS
const exportarDadosDeclaracaoDocente = async (
  docenteId: number,
  declaracaoId: number
): Promise<DadosExportados> => {
  const res = await axios.get<DadosExportados>(
    `docentes/${docenteId}/declaracoes/${declaracaoId}/exportar`
  );
  return res.data;
};

export default {
  private: {
    alteraProjeto,
    alteraItemReceitaDocente,
    alteraDeclaracaoItemProjeto,
    alteraFlagConclusao,
    buscaProjeto,
    buscaDeclaracaoByPeriodoId,
    buscaDeclaracao,
    criaDeclaracao,
    criaProjetoPesquisa,
    criaDeclaracaoItemProjeto,
    criaEmLoteDeclaracaoReceitaDocente,
    exportarDadosDeclaracaoDocente,
    listaProjetosByDeclaracaoId,
    listaProjetosByPeriodoId,
    listaDeclaracaoItemsProjeto,
    listaDeclaracaoReceitaDocente,
    importarItensDeProjeto,
    removeDeclaracaoItem,
  },
};
