
import Vue from "vue";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { Declaracao } from "@back-src/models/declaracao.model";
import requester from "../../requester";
import CustomText from "../CustomText.vue";
import ValidacaoMercurioIcon from "../ValidacaoMercurioIcon.vue";
import DeclaracaoItemFormDialog from "./DeclaracaoItemFormDialog.vue";
import DeclaracaoItemDialogInfo from "./DeclaracaoItemDialogInfo.vue";
import { AlteraVetoDeclaracaoItemDto } from "@back-src/admin/dto/altera-veto-declaracao-item.dto";
import {
  verificaItemDeclaracao,
  validaPatrimonioECadastro,
} from "@/utils/functions";

export default Vue.extend({
  name: "DeclaracaoUnidadeTabela",
  components: {
    CustomText,
    DeclaracaoItemDialogInfo,
    DeclaracaoItemFormDialog,
    ValidacaoMercurioIcon,
  },
  props: {
    unidadeIdProp: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    alterar: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    mostrarSituacao: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    validaPatrimonioECadastro,
    loading: {
      itensDeclaracao: true,
      periodoDeclaracao: false,
    },
    dialog: {
      verDeclaracaoItem: false,
      alterarDeclaracaoItem: false,
    },
    groupBy: {
      text: null,
      value: null,
      title: null,
    },
    alterarDeclaracaoItem: false,
    search: null,
    disciplinaId: null,
    declaracaoItemSelecionado: null,
    declaracao: null as Declaracao,
    periodosDeclaracao: [] as PeriodoDeclaracao[] | [],
  }),
  computed: {
    todosItensDesabilitados() {
      return !this.itemsComputed
        ?.map((item) => item.utilizar)
        .reduce((prox, acc) => prox || acc);
    },
    groupItems() {
      const items = [
        {
          title: "Disciplina",
          value: "disciplinaNome",
          text: null,
        },
        {
          title: "Espécie",
          value: "categoria.especie.nome",
          text: null,
        },
        {
          title: "Lote",
          value: "lote",
          text: (value) => (value ? "Sim" : "Não"),
        },
      ];
      const mostrarSituacao = {
        text: (value) => (value ? "Completo" : "Incompleto"),
        value: "situacao",
        title: "Situação",
      };
      const admin = {
        text: (value) => (value ? "Sim" : "Não"),
        value: "utilizar",
        title: "Habilitado",
      };
      const itemsReturn = [];
      itemsReturn.push(...items);
      if (this.mostrarSituacao) {
        itemsReturn.push(mostrarSituacao);
      }
      if (this.admin) {
        itemsReturn.push(admin);
      }
      return itemsReturn;
    },
    itemsComputed() {
      return this.declaracao?.declaracaoItems.map((item) => {
        return {
          ...item,
          disciplinaNome: `${item.disciplina.sigla} - ${item.disciplina.nome}`,
          categoriaAnimal: `${item.categoria.especie.nome}/${item.categoria.nome}`,
          situacao: verificaItemDeclaracao(item),
          bloqueado: !!item.apiPatrimonioBloqueado,
          validado: item.apiPatrimonioConsultadaAt,
        };
      });
    },
    unidadeId() {
      if (this.unidadeIdProp) return this.unidadeIdProp;
      return Number(this.$store.getters.usuario.unidadeId) || null;
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    headersComputed() {
      const headers = [
        {
          text: "Validado Mercúrio?",
          value: "validado",
          groupable: false,
          sortable: false,
          align: "center",
        },
        {
          text: "Número patrimônio",
          value: "numPatrimonio",
          groupable: false,
        },
        {
          text: "Número cadastro",
          value: "numCadastro",
          groupable: false,
        },
        {
          text: "Disciplina",
          align: "start",
          value: "disciplinaNome",
          groupable: true,
        },
        {
          text: "Espécie/Categoria",
          value: "categoriaAnimal",
          groupable: false,
        },
        { text: "Lote", value: "lote", groupable: true },
        { text: "Qtde", value: "qtdLote", groupable: false },
        { text: "Dias de uso", value: "diasUso", groupable: false },
        {
          text: this.alterar ? "Alterar" : "Visualizar",
          value: "actions",
          sortable: false,
          groupable: false,
          align: "center",
        },
      ];
      if (this.admin) {
        return [
          ...headers,
          {
            text: "Habilitado",
            value: "utilizar",
            groupable: true,
            sortable: false,
          },
        ];
      }
      if (this.mostrarSituacao) {
        return [
          {
            text: "Situação",
            value: "situacao",
            groupable: false,
            align: "center",
          },
          ...headers,
        ];
      }
      return headers;
    },
  },
  async mounted() {
    this.buscaDeclaracao();
  },
  methods: {
    groupBySelect(groupItem) {
      if (this.groupBy.value === groupItem.value) {
        this.groupBy.text = null;
        this.groupBy.value = null;
        this.groupBy.title = null;
      } else {
        this.groupBy.text = groupItem.text;
        this.groupBy.value = groupItem.value;
        this.groupBy.title = groupItem.title;
      }
    },
    verificaItemHabilitado(item: DeclaracaoItem) {
      if (this.admin && !item.utilizar) return "text--disabled";
      return "";
    },
    async submitAlteraVetoForm(item: DeclaracaoItem) {
      try {
        const formData = {
          utilizar: item.utilizar,
        } as AlteraVetoDeclaracaoItemDto;
        await requester.admin.private.alteraVetoDeclaracaoItem(
          formData,
          this.periodoDeclaracaoId,
          item?.declaracaoId,
          item?.id
        );
        await this.buscaDeclaracao();
        this.$root.$emit("toast-success", "Declaração atualizada!");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async submitAlteraVetoFormBulk(utilizar: boolean) {
      try {
        const formData = {
          utilizar: utilizar,
        } as AlteraVetoDeclaracaoItemDto;
        await requester.admin.private.alteraVetoDeclaracaoBulk(
          formData,
          this.periodoDeclaracaoId,
          this.declaracao?.id
        );
        await this.buscaDeclaracao();
        this.$root.$emit("toast-success", "Declaração atualizada!");
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    openDialogInfo(declaracaoItem: DeclaracaoItem) {
      this.declaracaoItemSelecionado = declaracaoItem;
      this.dialog.verDeclaracaoItem = true;
    },
    openDialogForm(declaracaoItem: DeclaracaoItem) {
      this.declaracaoItemSelecionado = declaracaoItem;
      this.dialog.alterarDeclaracaoItem = true;
    },
    async buscaDeclaracao() {
      this.loading.itensDeclaracao = true;
      this.$emit("loading-declaracao-begin");
      try {
        this.declaracao =
          await requester.unidade.private.buscaDeclaracaoByPeriodoId(
            this.unidadeId,
            this.periodoDeclaracaoId
          );
        this.$emit("declaracao-selecionada", this.declaracao);
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.$emit("loading-declaracao-end");
        this.loading.itensDeclaracao = false;
      }
    },
  },
  watch: {
    unidadeId() {
      this.buscaDeclaracao();
    },
    periodoDeclaracaoId() {
      this.buscaDeclaracao();
    },
  },
});
