import { PeriodoDeclaracao } from "@back-src/models/periodo-declaracao.model";
import { StatusPeriodosDeclaracao } from "./enums";
import { getDataHojeBdFormat } from "../../../back/src/utils/functions";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";

export const defineStatusPeriodoDeclaracao = (periodo: PeriodoDeclaracao) => {
  const hojeBd = getDataHojeBdFormat();
  if (periodo.dataInicio <= hojeBd && periodo.dataFim >= hojeBd) {
    return StatusPeriodosDeclaracao.EM_ANDAMENTO;
  } else if (periodo.dataInicio > hojeBd) {
    return StatusPeriodosDeclaracao.FUTURO;
  } else {
    return StatusPeriodosDeclaracao.FINALIZADO;
  }
};

export function validaPatrimonioECadastro(item: DeclaracaoItem) {
  const isPatrimonioOpcional = item.categoria.patrimonioOpcional;
  const isOrigemParticular = item.origemParticular;
  if (item.numPatrimonio) return true;
  if (isPatrimonioOpcional || isOrigemParticular) {
    if (item.numCadastro) return true;
  }
  return false;
}

export function verificaItemDeclaracao(item: DeclaracaoItem) {
  if (item.apiPatrimonioBloqueado === true) return false;
  if (!item.diasUso || !item.proprietario) {
    return false;
  }
  if (!validaPatrimonioECadastro(item)) {
    return false;
  }
  return true;
}

// valor numerico para permitir ordenacao nas tabelas da PRPI
export function getStatusNumericoMercurio(item: DeclaracaoItem): number {
  if (item.apiPatrimonioBloqueado) {
    // Bloqueado é o mais relevante (não deve ter mtos)
    return 1;
  }
  if (!item.numPatrimonio) {
    // pode ficar no meio, irrelevante
    return 3;
  }
  if (!item.apiPatrimonioConsultadaAt) {
    // Sem validação A PESAR de ter patrimonio é o amarelo, importante tbm. Pode ter varios
    return 2;
  } else {
    return 4;
  }
}

export const filterFormatCurrency = (
  value: number,
  showCurrencySign = true
) => {
  if (isNaN(value)) return "";
  if (showCurrencySign) {
    return new Intl.NumberFormat("pt-BR", {
      currency: "BRL",
      style: "currency",
    }).format(value);
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
};
