
import Vue from "vue";
import { Disciplina } from "@back-src/models/disciplina.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import DeclaracaoItemFormDialog from "../../components/declaracao/DeclaracaoItemFormDialog.vue";
import DeclaracaoItemDataIterator from "../declaracao/DeclaracaoItemDataIterator.vue";
import DeclaracaoItemImportarCard from "../declaracao/DeclaracaoItemImportarCard.vue";
import TabelaErrosImportacao from "../TabelaErrosImportacao.vue";
import requester from "../../requester";
import { Categoria } from "@back-src/models/categoria.model";
import CustomColInfo from "../../components/CustomColInfo.vue";
import { ResponseImportacao } from "@back-src/docente/interface/resultadoValidacao.interface";
export default Vue.extend({
  name: "DisciplinaDeclaracaoItem",
  components: {
    DeclaracaoItemDataIterator,
    DeclaracaoItemImportarCard,
    DeclaracaoItemFormDialog,
    TabelaErrosImportacao,
    CustomColInfo,
  },
  props: {
    disciplinaId: {
      type: Number,
      default: null,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    loading: {
      disciplina: true,
      animaisDeclarados: true,
      categorias: true,
      importacao: false,
      validacaoApiPatrimonio: false,
    },
    search: null,
    declaracaoItemId: null,
    alterarDeclaracaoItem: false,
    dialogDeclaracaoItem: false,
    disciplina: null as Disciplina | null,
    animaisDeclarados: [] as DeclaracaoItem[],
    categorias: [] as Categoria[],
    importacaoRejeitada: false,
    qtdeAnimaisImportar: 0,
    qtdeAnimaisValidacao: 0,
    validados: 0,
    msgImportacao:
      "Esta disciplina foi importada de declarações anteriores. Deseja importar os animais?",
    header: [
      {
        value: "id",
        text: "Id",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "especie",
        text: "Animal",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "categoria",
        text: "Categoria",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },

      {
        value: "diasUso",
        text: "Dias de utilização",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "lote",
        text: "Lote",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "center",
      },
      {
        value: "qtdLote",
        text: "Qtde de animais",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "obs",
        text: "Observações",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "actions",
        text: "Alterar",
        sortable: false,
        filterable: false,
        class: "text-no-wrap",
      },
    ],
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    oferecerImportacaoAnimais() {
      return (
        this.animaisDeclarados.length === 0 &&
        this.disciplina.importacaoDisciplinaId &&
        this.qtdeAnimaisImportar > 0
      );
    },
  },
  async mounted() {
    await this.buscaDisciplina();
    await this.listaCategorias();
    await this.listaAnimaisDeclarados();
    if (
      this.disciplina.importacaoDisciplinaId &&
      this.animaisDeclarados.length === 0
    ) {
      this.listaAnimaisImportacao();
    }
  },
  methods: {
    async importarAnimais() {
      const confirm = await this.$root.$confirm(
        "Importar animais",
        `Esta ação irá importar ${this.qtdeAnimaisImportar} animais da disciplina original. Os dados importados desta maneira poderão ser alterados ou excluídos posteriormente. Deseja prosseguir?`,
        {
          textConfirm: "Sim, importar",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      // importar
      this.loading.importacao = true;
      try {
        const resultado: ResponseImportacao =
          await requester.unidade.private.importarItensDeDisciplina(
            this.unidadeId,
            this.declaracaoId,
            {
              disciplinaOrigemId: this.disciplina.importacaoDisciplinaId,
              disciplinaDestinoId: this.disciplina.id,
            }
          );
        this.animaisDeclarados = [];
        this.loading.validacaoApiPatrimonio = true;

        for await (const novoItem of resultado.novosItens) {
          if (novoItem.numPatrimonio) {
            // se tiver numPatrimonio, checa e usa versão validada
            // Caso de erro, retorna status code (401)
            const validado =
              await requester.declaracaoItem.private.validaItemApiPatrimonio(
                novoItem.id
              );
            this.validados++;
            if (isNaN(Number(validado))) {
              this.animaisDeclarados.push(validado);
            } else {
              this.animaisDeclarados.push(novoItem);
            }
          } else {
            // senao, usa versão atual
            this.animaisDeclarados.push(novoItem);
          }
        }
        this.loading.validacaoApiPatrimonio = false;
        await this.buscaDisciplina();
        this.$root.$emit("toast-success", "Itens importados com sucesso!");
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.importacao = false;
      }
    },
    async recusarImportacao() {
      const confirm = await this.$root.$confirm(
        "Não importar animais",
        "Se prosseguir não será mais possível importar os dados dos animais declarados em anos anteriores e você deverá realizar o cadastro manualmente. Tem certeza que deseja prosseguir?",
        {
          textConfirm: "Sim, prosseguir",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.importacaoRejeitada = true;
    },
    async listaAnimaisImportacao() {
      try {
        const itensDeclaracao =
          await requester.unidade.private.listaDeclaracaoItemsDisciplina(
            this.disciplina.importacaoDisciplinaId,
            this.unidadeId
          );
        this.qtdeAnimaisImportar = itensDeclaracao.length;
        this.qtdeAnimaisValidacao = itensDeclaracao.filter(
          (el) => el.numPatrimonio
        ).length;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    openDialogInfo(declaracaoItemId: number) {
      this.declaracaoItemId = declaracaoItemId;
      this.alterarDeclaracaoItem = true;
      this.dialogDeclaracaoItem = true;
    },
    async buscaDisciplina() {
      this.loading.disciplina = true;
      try {
        const disciplinaReq = await requester.disciplina.private.busca(
          this.disciplinaId
        );
        if (disciplinaReq) this.disciplina = disciplinaReq;
        this.loading.disciplina = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaAnimaisDeclarados() {
      this.loading.animaisDeclarados = true;
      try {
        this.animaisDeclarados =
          await requester.unidade.private.listaDeclaracaoItemsDisciplina(
            this.disciplinaId,
            this.unidadeId
          );
        this.loading.animaisDeclarados = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
        this.loading.categorias = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
