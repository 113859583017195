
import Vue from "vue";
import DeclaracaoItemDialogInfo from "../declaracao/DeclaracaoItemDialogInfo.vue";
import ValidacaoMercurioIcon from "../ValidacaoMercurioIcon.vue";
import { getStatusNumericoMercurio } from "@/utils/functions";

enum ValuesFiltros {
  todos = "todos",
  sim = "Sim",
  nao = "Não",
}

export default Vue.extend({
  name: "TabelaItensAtivosDeclaracao",
  components: {
    DeclaracaoItemDialogInfo,
    ValidacaoMercurioIcon,
  },
  props: {
    itensDeclaracaoDoPeriodo: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ValuesFiltros,
    loading: false,
    search: null,
    dialogVerDeclaracaoItem: false,
    declaracaoItemIdSelecionado: null,
    filtro: {
      convenioUsp: ValuesFiltros.todos,
      particular: ValuesFiltros.todos,
      receita: ValuesFiltros.todos,
    },
  }),
  computed: {
    headersComputed() {
      const headers = [
        {
          text: "MercúrioWeb",
          value: "statusMercurio",
          align: "center",
        },
        {
          text: "Num. patrimônio",
          value: "numPatrimonio",
        },
        {
          text: "Num. cadastro",
          value: "numCadastro",
        },
        {
          text: "Unidade",
          value: "unidade",
        },
        {
          text: "Declarante",
          value: "declarante",
        },
        {
          text: "Projeto/Disciplina",
          value: "projetoOuDisciplina",
        },
        {
          text: "Espécie/categoria",
          value: "categoriaAnimal",
        },
        { text: "Qtde", value: "declaracaoItem.qtdLote" },
        { text: "Dias de uso", value: "diasUso" },
        { text: "Particular", value: "origemParticular" },
        { text: "Num. convênio USP", value: "convenioUsp" },
        {
          text: "Visualizar",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
      return headers;
    },
    itensComputedFiltered() {
      const itensComputed = this.itensDeclaracaoDoPeriodo.map((el) => ({
        ...el,
        //statusMercurio criado para ordenacao
        statusMercurio: getStatusNumericoMercurio({
          ...el,
          apiPatrimonioBloqueado: el.declaracaoItem.apiPatrimonioBloqueado,
          apiPatrimonioConsultadaAt:
            el.declaracaoItem.apiPatrimonioConsultadaAt,
        }),
        bloqueado: !!el.declaracaoItem.apiPatrimonioBloqueado,
        validado: el.declaracaoItem.apiPatrimonioConsultadaAt,
        projetoId: el.declaracaoItem.projetoPesquisa?.id || null,
        origemParticular: el.declaracaoItem.origemParticular ? "Sim" : "Não",
        convenioUsp: el.declaracaoItem.projetoPesquisa?.convenioUsp,
        categoriaAnimal: `${el.categoria.especie.nome}/${el.categoria.nome}`,
        projetoOuDisciplina: this.montaNomeDisciplinaOuProjeto(el),
        unidade: el.declaracao.unidade
          ? `${el.declaracao.unidade.sigla}`
          : `${el.declaracao.usuarioCreatedBy.unidade.sigla}`,
        declarante: el.declaracao.unidade
          ? `${el.declaracao.unidade.sigla}`
          : `${el.declaracao.usuarioCreatedBy.nome}`,
      }));
      return itensComputed.filter((el) => {
        if (this.filtro.convenioUsp === ValuesFiltros.sim && !el.convenioUsp) {
          return false;
        }
        if (this.filtro.convenioUsp === ValuesFiltros.nao && !!el.convenioUsp) {
          return false;
        }
        if (
          this.filtro.particular === ValuesFiltros.sim &&
          el.origemParticular === "Não"
        ) {
          return false;
        }
        if (
          this.filtro.particular === ValuesFiltros.nao &&
          el.origemParticular === "Sim"
        ) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    openDialogInfo(itemId) {
      this.declaracaoItemIdSelecionado = itemId;
      this.dialogVerDeclaracaoItem = true;
    },
    getFileUrl(projetoId: number) {
      return `${process.env.VUE_APP_BASE_URL}projetos_pesquisa/${projetoId}/convenio_empresa_arquivo/presigned_url`;
    },
    montaNomeDisciplinaOuProjeto(item) {
      if (item.declaracaoItem.disciplina) {
        return `${item.declaracaoItem.disciplina.sigla} - ${item.declaracaoItem.disciplina.nome}`;
      }
      if (item.declaracaoItem.projetoPesquisa) {
        return item.declaracaoItem.projetoPesquisa.titulo;
      }
      return "";
    },
  },
  watch: {
    dialogVerDeclaracaoItem() {
      if (!this.dialogVerDeclaracaoItem) {
        this.declaracaoItemIdSelecionado = null;
      }
    },
  },
});
