
import Vue from "vue";
import CustomText from "../CustomText.vue";
import { ItemDeclaradoVerificacao } from "../../utils/constants";
export default Vue.extend({
  name: "DeclaracaoVerConflitoDialog",
  components: { CustomText },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    animaisDeclarados: {
      type: Array,
      default: () => [] as ItemDeclaradoVerificacao[],
    },
    numPatrimonio: {
      type: String,
      default: null,
    },
    numCadastro: {
      type: String,
      default: null,
    },
    tipoConflito: {
      type: String,
      default: null,
    },
    diasDisponiveis: {
      type: Number,
      default: null,
    },
    declaracaoItemId: {
      type: Number,
    },
  },
  data: () => ({
    headers: [
      { text: "Tipo de declarante" },
      { text: "Responsável" },
      { text: "Email" },
      { text: "Telefone" },
      { text: "Dias de uso" },
      { text: "Status" },
      { text: "Última atualização" },
    ],
  }),
  computed: {
    itemsComputed() {
      const data = [];
      this.animaisDeclarados.forEach((item) => {
        if (item.id !== this.declaracaoItemId) data.push(item);
      });
      if (this.tipoConflito === "patrimonio") {
        return data.filter((el) => el.numPatrimonio === this.numPatrimonio);
      } else if (this.tipoConflito === "cadastro") {
        return data.filter((el) => el.numCadastro === this.numCadastro);
      }
      return data.filter(
        (el) =>
          el.numCadastro === this.numCadastro ||
          el.numPatrimonio === this.numPatrimonio
      );
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.$emit("input", false);
    },
  },
});
