
import Vue from "vue";
import { Categoria } from "@back-src/models/categoria.model";
import { Especie } from "@back-src/models/especie.model";
import { CriaDeclaracaoItemDto } from "@back-src/declaracao/dto/cria-declaracao-item.dto";
import { AlteraDeclaracaoItemDto } from "@back-src/declaracao/dto/altera-declaracao-item.dto";
import requester from "../../requester";
import DeclaracaoVerConflitoDialog from "./DeclaracaoVerConflitoDialog.vue";
import DeclaracaoItemStatusBuscaPatrimonio from "./DeclaracaoItemStatusBuscaPatrimonio.vue";
import {
  fileMaxSize,
  isCategoriaValida,
  isEspecieValida,
  isNumberMax,
  isQtdLoteValida,
  rulesPatrimonioValid,
  rulesRequired,
} from "../../utils/input-rules";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { OrigemAnimal, Proprietarios } from "@/utils/enums";
import { ItemDeclaradoVerificacao } from "@/utils/constants";
import { Declaracao } from "@back-src/models/declaracao.model";
export default Vue.extend({
  name: "DeclaracaoItemFormDialog",
  components: {
    DeclaracaoVerConflitoDialog,
    DeclaracaoItemStatusBuscaPatrimonio,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isPrefeitura: {
      type: Boolean,
      default: false,
    },
    unidadeId: {
      type: Number,
      default: null,
    },
    docenteId: {
      type: Number,
      default: null,
    },
    projetoId: {
      type: Number,
      default: null,
    },
    alterarDeclaracaoItem: {
      type: Boolean,
      default: false,
    },
    declaracaoItemId: {
      type: Number,
    },
    declaracaoId: {
      type: Number,
    },
    periodoDeclaracaoId: {
      type: Number,
    },
    disciplinaId: {
      type: Number,
    },
  },
  data: () => ({
    loading: {
      especies: true,
      categorias: true,
      declaracaoItem: true,
    },
    loadingApi: false,
    loadingAnimaisDeclarados: false,
    rules: {
      required: rulesRequired,
      dias: isNumberMax,
      patrimonioCadastroValido: rulesPatrimonioValid,
      fileSize: fileMaxSize,
    },
    dialogVerConflitos: false,
    tipoConflito: null, // patrimonio ou cadastro
    dialogConfirmacao: false,
    especies: null as Especie[],
    categorias: null as Categoria[],
    declaracaoItem: null as DeclaracaoItem,
    file: null,
    form: {
      especieId: null,
      categoriaId: null,
      lote: null,
      origemParticular: null,
      qtdLote: null,
      numPatrimonio: null,
      numCadastro: null,
      proprietario: null,
      diasUso: null,
      destinoPosUso: null,
      destinatarioPosUso: null,
      destinoPosUsoOutros: null,
      obs: null,
      apiPatrimonioConsultadaAt: null,
      apiPatrimonioDados: null,
      apiPatrimonioBloqueado: false,
    },
    // armazena animais com mesmo numero de patrimonio ou cadastro
    animaisDeclarados: [],
    categoriaDeclaradaPatrimonio: null,
    categoriaDeclaradaCadastro: null,
    hint: {
      numPatrimonio: "",
      especiePatrimonio: "",
      categoriaPatrimonio: "",
      qtdLotePatrimonio: "",
      especieCadastro: "",
      categoriaCadastro: "",
      qtdLoteCadastro: "",
    },
    qtdLotePatrimonio: null,
    qtdLoteCadastro: null,
    errorText: {
      especieJaDeclaradaPatrimonio:
        "Este número de patrimônio foi utilizado com a espécie: ",
      categoriaJaDeclaradaPatrimonio:
        "Este número de patrimônio foi utilizado com a categoria: ",
      especieJaDeclaradaCadastro:
        "Este número de cadastro foi utilizado com a espécie: ",
      categoriaJaDeclaradaCadastro:
        "Este número de cadastro foi utilizado com a categoria: ",
      qtdLotePatrimonio: `Este número de patrimônio foi declarado para `,
      qtdLoteCadastro: `Este número de cadastro foi declarado para `,
      submit: "Existem campos inválidos. Corrija os erros e tente novamente.",
    },
  }),
  computed: {
    animaisDeclaradosIds() {
      return this.animaisDeclarados.map((el) => el.id);
    },
    patrimonioValido() {
      return this.rules.patrimonioCadastroValido(this.form?.numPatrimonio);
    },
    cadastroValido() {
      return this.rules.patrimonioCadastroValido(this.form?.numCadastro);
    },
    hintEspecie() {
      const hintArray = [];
      if (this.hint.especiePatrimonio)
        hintArray.push(this.hint.especiePatrimonio);
      if (this.hint.especieCadastro) hintArray.push(this.hint.especieCadastro);
      return hintArray;
    },
    hintCategoria() {
      const hintArray = [];
      if (this.hint.categoriaPatrimonio)
        hintArray.push(this.hint.categoriaPatrimonio);
      if (this.hint.categoriaCadastro)
        hintArray.push(this.hint.categoriaCadastro);
      return hintArray;
    },
    hintQtdLote() {
      const hintArray = [];
      if (this.hint.qtdLotePatrimonio)
        hintArray.push(this.hint.qtdLotePatrimonio);
      if (this.hint.qtdLoteCadastro) hintArray.push(this.hint.qtdLoteCadastro);
      return hintArray;
    },
    nomeTermoCooperacaoArquivo() {
      return this.declaracaoItem?.termoCooperacaoArquivo?.originalname;
    },
    hrefTermoCooperacaoArquivo() {
      return this.declaracaoItem?.origemParticular
        ? `${process.env.VUE_APP_BASE_URL}declaracao_items/${this.declaracaoItem.id}/termo_cooperacao/presigned_url`
        : "";
    },
    origemAnimal() {
      return Object.values(OrigemAnimal);
    },
    origemAnimalOutros() {
      return OrigemAnimal.OUTROS;
    },
    proprietarios() {
      return Object.values(Proprietarios);
    },
    rulesNumberMax() {
      return isNumberMax(this.form?.diasUso, this.diasDisponiveis);
    },

    rulesEspeciePatrimonioValida() {
      return isEspecieValida(
        this.form.especieId,
        this.categoriaDeclaradaPatrimonio?.especie?.id,
        this.categoriaDeclaradaPatrimonio?.especie?.nome,
        true
      );
    },
    rulesEspecieCadastroValida() {
      return isEspecieValida(
        this.form.especieId,
        this.categoriaDeclaradaCadastro?.especie?.id,
        this.categoriaDeclaradaCadastro?.especie?.nome,
        false
      );
    },
    rulesCategoriaPatrimonioValida() {
      return isCategoriaValida(
        this.form.categoriaId,
        this.categoriaDeclaradaPatrimonio?.id,
        this.categoriaDeclaradaPatrimonio?.nome,
        true
      );
    },
    rulesCategoriaCadastroValida() {
      return isCategoriaValida(
        this.form.categoriaId,
        this.categoriaDeclaradaCadastro?.id,
        this.categoriaDeclaradaCadastro?.nome,
        false
      );
    },
    rulesQtdLotePatrimonioValida() {
      return isQtdLoteValida(
        Number(this.qtdLotePatrimonio),
        Number(this.form.qtdLote),
        true
      );
    },
    rulesQtdLoteCadastroValida() {
      return isQtdLoteValida(
        Number(this.qtdLoteCadastro),
        Number(this.form.qtdLote),
        false
      );
    },
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    especiesComputed() {
      return this.especies.map((especie) => {
        return { text: especie.nome, value: especie.id };
      });
    },
    categoriasFiltered() {
      return (
        this.categorias?.filter(
          (categoria) => categoria?.especie?.id === this.form?.especieId
        ) || []
      );
    },
    categoriasComputed() {
      return this.categoriasFiltered.map((categoria) => {
        return { text: categoria.nome, value: categoria.id };
      });
    },
    disabledLoteComputed() {
      if (!this.form.categoriaId) return true;
      const categoria = this.categorias.find(
        (el) => el.id === this.form.categoriaId
      );
      if (categoria.permiteCabeca && categoria.permiteLote) return false;
      return true;
    },
    patrimonioJaUtilizado() {
      if (this.numPatrimonioCompleto) {
        return (
          this.animaisDeclarados.filter(
            (el) => el.numPatrimonio === this.form.numPatrimonio
          ).length > 0
        );
      }
      return false;
    },
    cadastroJaUtilizado() {
      if (this.numCadastroCompleto) {
        return (
          this.animaisDeclarados.filter(
            (el) => el.numCadastro === this.form.numCadastro
          ).length > 0
        );
      }
      return false;
    },
    numPatrimonioCompleto() {
      return this.form?.numPatrimonio?.length !== 11 ? false : true;
    },
    numCadastroCompleto() {
      return this.form?.numCadastro?.length !== 11 ? false : true;
    },
    diasDisponiveis() {
      let diasUtilizadosCadastro = 0;
      if (this.numCadastroCompleto) {
        this.animaisDeclarados
          .filter((el) => el.numCadastro === this.form.numCadastro)
          .forEach((item) => {
            diasUtilizadosCadastro += item.diasUso;
          });
      }

      let diasUtilizadosPatrimonio = 0;
      if (this.numPatrimonioCompleto) {
        this.animaisDeclarados
          .filter((el) => el.numPatrimonio === this.form.numPatrimonio)
          .forEach((item) => {
            diasUtilizadosPatrimonio += item.diasUso;
          });
      }
      return 365 - Math.max(diasUtilizadosPatrimonio, diasUtilizadosCadastro);
    },
  },
  async mounted() {
    await this.listaEspecies();
    await this.listaCategorias();
    this.loading.declaracaoItem = false;
    if (this.declaracaoItemId) {
      this.loading.declaracaoItem = true;
      await this.buscaDeclaracaoItem();
      this.consultaNumeros();
    }
  },
  methods: {
    buscaApiOk(dadosApi, bloquearItem) {
      this.form.apiPatrimonioBloqueado = bloquearItem;
      this.form.apiPatrimonioConsultadaAt = Date();
      this.form.apiPatrimonioDados = dadosApi;
    },
    erroApi(err) {
      // consulta deu algum erro. Não seta dados
      this.form.apiPatrimonioBloqueado = false;
      this.form.apiPatrimonioConsultadaAt = null;
      this.form.apiPatrimonioDados = null;
    },
    animalNaoCadastradoApi() {
      // sinaliza bloqueio, não tem dados
      this.form.apiPatrimonioBloqueado = true;
      this.form.apiPatrimonioConsultadaAt = Date();
      this.form.apiPatrimonioDados = null;
    },
    async openDialogRemoverItem() {
      const confirm = await this.$root.$confirm(
        "Remover item",
        "Esta ação irá remover este item da declaração. Deseja prosseguir?",
        {
          textConfirm: "Sim, remover",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.removeDeclaracaoItem();
    },
    openDialogConflito(tipoConflito) {
      this.tipoConflito = tipoConflito;
      this.dialogVerConflitos = true;
    },
    pushToAnimaisDeclarados(declaracoes: Declaracao[]) {
      declaracoes.forEach((declaracao: Declaracao) =>
        declaracao.declaracaoItems.forEach((item: DeclaracaoItem) => {
          // verifica se item já está no array
          if (!this.animaisDeclaradosIds.includes(item.id)) {
            this.animaisDeclarados.push({
              id: item.id,
              especie: item.categoria?.especie?.nome || null,
              categoria: item.categoria
                ? JSON.parse(JSON.stringify(item.categoria))
                : null,
              completa: declaracao.completa,
              diasUso: item.diasUso || 0,
              qtdLote: item.qtdLote || 0,
              numCadastro: item.numCadastro,
              numPatrimonio: item.numPatrimonio,
              tipoDeclarante: declaracao.tipoDeclarante,
              unidadeSigla: declaracao.unidade?.sigla || null,
              usuarioEmailPrincipal:
                declaracao?.usuarioUpdatedBy?.emailPrincipal || null,
              usuarioNome: declaracao?.usuarioUpdatedBy?.nome || null,
              usuarioTelefone: declaracao?.usuarioUpdatedBy?.telefone || null,
              updatedAt: item.updatedAt || null,
            } as ItemDeclaradoVerificacao);
          }
        })
      );
    },
    async consultaNumeros() {
      this.hint.categoriaCadastro = "";
      this.hint.especieCadastro = "";
      this.hint.qtdLoteCadastro = "";
      this.categoriaDeclaradaCadastro = null;
      this.qtdLoteCadastro = null;
      this.hint.categoriaPatrimonio = "";
      this.hint.especiePatrimonio = "";
      this.hint.qtdLotePatrimonio = "";
      this.animaisDeclarados = [];
      this.categoriaDeclaradaPatrimonio = null;
      this.qtdLotePatrimonio = null;

      if (this.numPatrimonioCompleto) {
        await this.verificaNumPatrimonio();
      } else {
        // se o patrimonio não estiver completo, zera info da API
        this.form.apiPatrimonioBloqueado = false;
        this.form.apiPatrimonioConsultadaAt = null;
        this.form.apiPatrimonioDados = null;
      }
      if (this.numCadastroCompleto) {
        await this.verificaNumCadastro();
      }
    },
    async verificaNumPatrimonio() {
      this.loadingAnimaisDeclarados = true;
      try {
        const declaracoes =
          await requester.periodoDeclaracao.private.verificaNumPatrimonio(
            this.periodoDeclaracaoId,
            this.form.numPatrimonio,
            this.declaracaoItemId || null
          );
        this.pushToAnimaisDeclarados(declaracoes);
        if (this.patrimonioJaUtilizado) {
          this.categoriaDeclaradaPatrimonio =
            this.verificaEspecieDeclarada("patrimonio");
          this.qtdLotePatrimonio = this.verificaqtdLoteDeclarado("patrimonio");
          if (this.categoriaDeclaradaPatrimonio) {
            this.hint.especiePatrimonio =
              this.errorText.especieJaDeclaradaPatrimonio +
              this.categoriaDeclaradaPatrimonio.especie.nome;
            this.hint.categoriaPatrimonio =
              this.errorText.categoriaJaDeclaradaPatrimonio +
              this.categoriaDeclaradaPatrimonio.nome;
            this.hint.qtdLotePatrimonio =
              this.qtdLotePatrimonio === 1
                ? `${this.errorText.qtdLotePatrimonio} ${this.qtdLotePatrimonio} animal`
                : `${this.errorText.qtdLotePatrimonio} ${this.qtdLotePatrimonio} animais`;
          }
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loadingAnimaisDeclarados = false;
      }
    },
    async verificaNumCadastro() {
      this.loadingAnimaisDeclarados = true;
      try {
        const declaracoes =
          await requester.periodoDeclaracao.private.verificaNumCadastro(
            this.periodoDeclaracaoId,
            this.form.numCadastro,
            this.declaracaoItemId || null
          );
        this.pushToAnimaisDeclarados(declaracoes);
        if (this.cadastroJaUtilizado) {
          this.categoriaDeclaradaCadastro =
            this.verificaEspecieDeclarada("cadastro");
          this.qtdLoteCadastro = this.verificaqtdLoteDeclarado("cadastro");
          if (this.categoriaDeclaradaCadastro) {
            this.hint.especieCadastro =
              this.errorText.especieJaDeclaradaCadastro +
              this.categoriaDeclaradaCadastro.especie.nome;
            this.hint.categoriaCadastro =
              this.errorText.categoriaJaDeclaradaCadastro +
              this.categoriaDeclaradaCadastro.nome;
            this.hint.qtdLoteCadastro =
              this.qtdLoteCadastro === 1
                ? `${this.errorText.qtdLoteCadastro} ${this.qtdLoteCadastro} animal`
                : `${this.errorText.qtdLoteCadastro} ${this.qtdLoteCadastro} animais`;
          }
        }
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loadingAnimaisDeclarados = false;
      }
    },
    verificaEspecieDeclarada(tipo) {
      if (this.animaisDeclarados.length === 0) return;
      let categoria = null;
      let chaveArray;
      if (tipo === "patrimonio") {
        chaveArray = "numPatrimonio";
      }
      if (tipo === "cadastro") {
        chaveArray = "numCadastro";
      }
      this.animaisDeclarados
        .filter((el) => el[chaveArray] === this.form[chaveArray])
        .forEach((item) => {
          if (!categoria || categoria.id === item.categoria.id) {
            categoria = item.categoria;
          }
        });
      return categoria;
    },
    verificaqtdLoteDeclarado(tipo) {
      if (this.animaisDeclarados.length === 0) return;
      let qtdLote = null;
      this.animaisDeclarados.forEach((item) => {
        if (
          tipo === "patrimonio" &&
          this.form.numPatrimonio === item.numPatrimonio
        ) {
          qtdLote = item.qtdLote;
        } else if (
          tipo === "cadastro" &&
          this.form.numCadastro === item.numCadastro
        ) {
          qtdLote = item.qtdLote;
        }
      });
      return qtdLote;
    },
    async buscaDeclaracaoItem() {
      try {
        this.declaracaoItem =
          await requester.unidade.private.buscaDeclaracaoItem(
            this.unidadeId,
            this.declaracaoItemId
          );
        const categoria = this.categorias.find(
          (categoria) => this.declaracaoItem?.categoriaId === categoria.id
        );
        this.form = { ...this.declaracaoItem, especieId: categoria.especieId };
        if (!this.proprietarios.includes(this.form.proprietario)) {
          this.form.proprietario = null;
        }
        this.loading.declaracaoItem = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.especies = false;
      }
    },
    verificaLote() {
      //VERIFICA DE POSSUI CATEGORIA ESCOLHIDA
      if (!this.form.categoriaId) {
        this.form.lote = null;
        this.form.qtdLote = null;
        return;
      }
      //VERIFICA SE A CATEGORIA ESCOLHIDA PERMITE CABECA E/OU LOTE
      const categoria = this.categorias.find(
        (el) => el.id === this.form.categoriaId
      );
      if (categoria.permiteCabeca && categoria.permiteLote) {
        this.form.lote = null;
        this.form.qtdLote = null;
      } else if (categoria.permiteCabeca) {
        this.form.lote = false;
        this.form.qtdLote = 1;
      } else {
        this.form.lote = true;
        this.form.qtdLote = null;
      }
    },
    alteraQtdAnimal() {
      this.form?.lote ? (this.form.qtdLote = null) : (this.form.qtdLote = 1);
    },
    async listaEspecies() {
      try {
        this.especies = await requester.especie.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.especies = false;
      }
    },
    async listaCategorias() {
      try {
        this.categorias = await requester.categoria.private.lista();
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.categorias = false;
      }
    },
    closeDialog() {
      this.$refs?.form?.reset();
      this.$emit("close");
      this.$emit("input", false);
    },
    validaForm() {
      return this.$refs.form.validate();
    },
    async submitCreateForm() {
      if (!this.validaForm()) {
        this.$root.$emit("toast-error", this.errorText.submit);
        return;
      }
      try {
        const data = {
          categoriaId: Number(this.form.categoriaId),
          lote: this.form?.lote === null ? null : this.form?.lote,
          origemParticular:
            this.form?.origemParticular === null
              ? null
              : this.form?.origemParticular,
          qtdLote: this.form?.qtdLote ? Number(this.form?.qtdLote) : null,
          numPatrimonio: this.form?.numPatrimonio
            ? this.form?.numPatrimonio.trim()
            : null,
          numCadastro: this.form?.numCadastro
            ? this.form?.numCadastro.trim()
            : null,
          proprietario: this.form?.proprietario
            ? this.form?.proprietario.trim()
            : null,
          diasUso: this.form?.diasUso ? Number(this.form?.diasUso) : null,
          destinoPosUso: this.form?.destinoPosUso
            ? this.form?.destinoPosUso != this.origemAnimalOutros
              ? this.form?.destinoPosUso.trim()
              : this.form?.destinoPosUsoOutros.trim()
            : null,
          destinatarioPosUso: this.form?.destinatarioPosUso
            ? this.form?.destinatarioPosUso.trim()
            : null,
          obs: this.form?.obs ? this.form?.obs.trim() : null,
          apiPatrimonioConsultadaAt: this.form.apiPatrimonioConsultadaAt,
          apiPatrimonioDados: this.form.apiPatrimonioDados,
          apiPatrimonioBloqueado: this.form.apiPatrimonioBloqueado,
        } as CriaDeclaracaoItemDto;
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("file", this.file);
        if (this.isPrefeitura) {
          await requester.unidade.private.criaDeclaracaoItemPrefeitura(
            formData,
            this.declaracaoId,
            this.unidadeId
          );
        } else if (this.unidadeId) {
          await requester.unidade.private.criaDeclaracaoItemDisciplina(
            formData,
            this.declaracaoId,
            this.disciplinaId,
            this.unidadeId
          );
        } else if (this.docenteId) {
          await requester.docente.private.criaDeclaracaoItemProjeto(
            formData,
            this.declaracaoId,
            this.projetoId,
            this.docenteId
          );
        }
        this.$root.$emit("toast-success", "Animal cadastrado!");
        this.$emit("animal-cadastrado");
        this.closeDialog();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async submitAlteraForm() {
      if (!this.validaForm()) {
        this.$root.$emit("toast-error", this.errorText.submit);
        return;
      }
      try {
        const data = {
          categoriaId: Number(this.form.categoriaId),
          origemParticular:
            this.form?.origemParticular === null
              ? null
              : this.form?.origemParticular,
          lote: this.form?.lote === null ? null : this.form?.lote,
          qtdLote: this.form?.qtdLote ? Number(this.form?.qtdLote) : null,
          numPatrimonio: this.form?.numPatrimonio
            ? this.form?.numPatrimonio.trim()
            : null,
          numCadastro: this.form?.numCadastro
            ? this.form?.numCadastro.trim()
            : null,
          proprietario: this.form?.proprietario
            ? this.form?.proprietario.trim()
            : null,
          diasUso: this.form?.diasUso ? Number(this.form?.diasUso) : null,
          destinoPosUso: this.form?.destinoPosUso
            ? this.form?.destinoPosUso != this.origemAnimalOutros
              ? this.form?.destinoPosUso.trim()
              : this.form?.destinoPosUsoOutros.trim()
            : null,
          destinatarioPosUso: this.form?.destinatarioPosUso
            ? this.form?.destinatarioPosUso.trim()
            : null,
          obs: this.form?.obs ? this.form?.obs.trim() : null,
          apiPatrimonioConsultadaAt: this.form.apiPatrimonioConsultadaAt,
          apiPatrimonioDados: this.form.apiPatrimonioDados,
          apiPatrimonioBloqueado: this.form.apiPatrimonioBloqueado,
        } as AlteraDeclaracaoItemDto;
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("file", this.file);
        if (this.isPrefeitura) {
          await requester.unidade.private.alteraDeclaracaoItemPrefeitura(
            formData,
            this.declaracaoItemId,
            this.unidadeId
          );
        } else if (this.unidadeId) {
          await requester.unidade.private.alteraDeclaracaoItemDisciplina(
            formData,
            this.declaracaoItemId,
            this.declaracaoItem?.disciplinaId,
            this.unidadeId
          );
        } else if (this.docenteId) {
          await requester.docente.private.alteraDeclaracaoItemProjeto(
            this.declaracaoId,
            this.docenteId,
            formData,
            this.declaracaoItemId,
            this.projetoId
          );
        }
        this.$root.$emit("toast-success", "Animal atualizado!");
        this.$emit("animal-atualizado");
        this.closeDialog();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async removeDeclaracaoItem() {
      try {
        if (this.unidadeId) {
          await requester.unidade.private.removeDeclaracaoItem(
            this.declaracaoItemId,
            this.declaracaoItem?.disciplinaId,
            this.unidadeId
          );
        } else if (this.docenteId) {
          await requester.unidade.private.removeDeclaracaoItem(
            this.declaracaoItemId,
            this.projetoId,
            this.docenteId
          );
        }
        this.$emit("animal-atualizado");
        this.closeDialog();
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
