
import Vue from "vue";
import requester from "../../requester";
import { Categoria } from "@back-src/models/categoria.model";
import { DeclaracaoItem } from "@back-src/models/declaracao-item.model";
import { ProjetoPesquisa } from "@back-src/models/projeto-pesquisa.model";
import { ResponseImportacao } from "@back-src/docente/interface/resultadoValidacao.interface";
import DeclaracaoItemFormDialog from "../declaracao/DeclaracaoItemFormDialog.vue";
import DeclaracaoItemDataIterator from "../declaracao/DeclaracaoItemDataIterator.vue";
import DocenteProjetoInfoCard from "./DocenteProjetoInfoCard.vue";
import DeclaracaoItemImportarCard from "../declaracao/DeclaracaoItemImportarCard.vue";
import TabelaErrosImportacao from "../TabelaErrosImportacao.vue";
export default Vue.extend({
  components: {
    DeclaracaoItemImportarCard,
    DocenteProjetoInfoCard,
    DeclaracaoItemFormDialog,
    DeclaracaoItemDataIterator,
    TabelaErrosImportacao,
  },
  name: "DocenteProjetoDeclaracaoItemWindow",
  props: {
    projetoId: {
      type: Number,
      default: null,
    },
    periodoDeclaracaoId: {
      type: Number,
      default: null,
    },
    declaracaoId: {
      type: Number,
    },
    usuarioId: {
      type: Number,
    },
  },
  data: () => ({
    loading: {
      projeto: true,
      animaisDeclarados: false,
      categorias: true,
      importacao: false,
      validacaoApiPatrimonio: false,
    },
    search: null,
    declaracaoItemId: null,
    alterarDeclaracaoItem: false,
    dialogDeclaracaoItem: false,
    projeto: null as ProjetoPesquisa | null,
    animaisDeclarados: [] as DeclaracaoItem[] | null,
    categorias: [] as Categoria[] | null,
    importacaoRejeitada: false,
    qtdeAnimaisImportar: 0,
    qtdeAnimaisValidacao: 0,
    validados: 0,
    msgImportacao:
      "Este projeto foi importado de declarações anteriores. Deseja importar os animais?",
    header: [
      {
        value: "id",
        text: "Id",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "especie",
        text: "Animal",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "categoria",
        text: "Categoria",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },

      {
        value: "diasUso",
        text: "Dias de utilização",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "lote",
        text: "Lote",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "center",
      },
      {
        value: "qtdLote",
        text: "Qtde de animais",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
        align: "right",
      },
      {
        value: "obs",
        text: "Observações",
        sortable: true,
        filterable: true,
        class: "text-no-wrap",
      },
      {
        value: "actions",
        text: "Alterar",
        sortable: false,
        filterable: false,
        class: "text-no-wrap",
      },
    ],
  }),
  computed: {
    loadingPageComputed() {
      return Object.values(this.loading).reduce((prox, acc) => prox || acc);
    },
    oferecerImportacaoAnimais() {
      return (
        this.animaisDeclarados.length === 0 &&
        this.projeto.importacaoProjetoId &&
        this.qtdeAnimaisImportar > 0
      );
    },
  },
  async mounted() {
    await this.buscaProjeto();
    await this.listaCategorias();
    await this.listaAnimaisDeclarados();
    if (
      this.projeto.importacaoProjetoId &&
      this.animaisDeclarados.length === 0
    ) {
      this.listaAnimaisImportacao();
    }
  },
  methods: {
    async importarAnimais() {
      const confirm = await this.$root.$confirm(
        "Importar animais",
        `Esta ação irá importar ${this.qtdeAnimaisImportar} animais do projeto original. Os dados importados desta maneira poderão ser alterados ou excluídos posteriormente. Deseja prosseguir?`,
        {
          textConfirm: "Sim, importar",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      // importar
      this.loading.importacao = true;
      try {
        const resultado: ResponseImportacao =
          await requester.docente.private.importarItensDeProjeto(
            this.usuarioId,
            this.declaracaoId,
            {
              projetoOrigemId: this.projeto.importacaoProjetoId,
              projetoDestinoId: this.projeto.id,
            }
          );
        this.animaisDeclarados = [];
        this.loading.validacaoApiPatrimonio = true;

        for await (const novoItem of resultado.novosItens) {
          if (novoItem.numPatrimonio) {
            // se tiver numPatrimonio, checa e usa versão validada
            // Caso de erro, retorna status code (401)
            const validado =
              await requester.declaracaoItem.private.validaItemApiPatrimonio(
                novoItem.id
              );
            this.validados++;
            if (isNaN(Number(validado))) {
              this.animaisDeclarados.push(validado);
            } else {
              this.animaisDeclarados.push(novoItem);
            }
          } else {
            // senao, usa versão atual
            this.animaisDeclarados.push(novoItem);
          }
        }
        this.loading.validacaoApiPatrimonio = false;
        await this.buscaProjeto();
        this.$root.$emit("toast-success", "Itens importados com sucesso!");
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.importacao = false;
      }
    },
    async recusarImportacao() {
      const confirm = await this.$root.$confirm(
        "Não importar animais",
        "Se prosseguir não será mais possível importar os dados dos animais declarados em anos anteriores e você deverá realizar o cadastro manualmente. Tem certeza que deseja prosseguir?",
        {
          textConfirm: "Sim, prosseguir",
          textCancel: "Não, cancelar",
          maxWidth: 600,
        }
      );
      if (!confirm) return;
      this.importacaoRejeitada = true;
    },
    async listaAnimaisImportacao() {
      try {
        const itensDeclaracao =
          await requester.docente.private.listaDeclaracaoItemsProjeto(
            this.projeto.importacaoProjetoId,
            this.usuarioId
          );
        this.qtdeAnimaisImportar = itensDeclaracao.length;
        this.qtdeAnimaisValidacao = itensDeclaracao.filter(
          (el) => el.numPatrimonio
        ).length;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    openDialogInfo(declaracaoItemId: number) {
      this.declaracaoItemId = declaracaoItemId;
      this.alterarDeclaracaoItem = true;
      this.dialogDeclaracaoItem = true;
    },
    async buscaProjeto() {
      this.loading.projeto = true;
      try {
        const projetoReq = await requester.docente.private.buscaProjeto(
          this.declaracaoId,
          this.periodoDeclaracaoId,
          this.projetoId,
          this.usuarioId
        );
        if (projetoReq) this.projeto = projetoReq;
        this.loading.projeto = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
    async listaAnimaisDeclarados() {
      this.loading.animaisDeclarados = true;
      try {
        this.animaisDeclarados =
          await requester.docente.private.listaDeclaracaoItemsProjeto(
            this.projetoId,
            this.usuarioId
          );
      } catch (err) {
        this.$root.$errorHandler(err);
      } finally {
        this.loading.animaisDeclarados = false;
      }
    },
    async listaCategorias() {
      this.loading.categorias = true;
      try {
        this.categorias = await requester.categoria.private.lista();
        this.loading.categorias = false;
      } catch (err) {
        this.$root.$errorHandler(err);
      }
    },
  },
});
